import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { navVariants } from "../utils/motion";
import { urlFor, client } from "../client";
import { TitleText } from "./CustomText";

const Blog = () => {
    const [media, setMedia] = useState(null); 
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/blogs");
    };

    useEffect(() => {
        const query = '*[_type == "blog"]';
        client.fetch(query).then((data) => {
            const sortedBlogs = data.sort(
                (a, b) => new Date(b.publishedAt) - new Date(a.publishedAt)
            );
            setMedia(sortedBlogs[0]);
        });
    }, []);

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(dateString).toLocaleDateString("es-ES", options);
    };

    if (!media) {
        return <p>Cargando...</p>; // O reemplázalo con tu componente de carga
    }

    return (
        <>
            <TitleText title="Visita nuestro Blog!" textStyles="text-black text-3xl py-3" />

            <section className="flex flex-col justify-center items-center w-full">
                <motion.div
                    variants={navVariants}
                    initial="hidden"
                    whileInView="show"
                    className="w-full flex flex-col items-center"
                >
                    <div className="h-[0.09rem] w-9/12 bg-black mt-8" />
                    <div className="h-[0.6rem] w-9/12 bg-black mt-16 mb-10" />

                    <div className="flex w-9/12">
                        <div key={media._id} className="flex max-lg:flex-col w-full">
                            <div className="w-1/2 max-lg:w-full flex flex-col justify-center items-center">
                                {media.imgUrl && media.imgUrl.asset && media.imgUrl.asset._ref.includes("image") ? (
                                    <img src={urlFor(media.imgUrl)} alt={media.title} />
                                ) : null}

                                {media.link && media.link.asset && media.link.asset._ref.includes("file") ? (
                                    media.link.asset._ref.includes("pdf") ? (
                                        <iframe
                                            src={urlFor(media.link)}
                                            width="100%"
                                            height="500px"
                                            title={media.title}
                                        >
                                            Tu navegador no soporta la previsualización de PDFs.
                                        </iframe>
                                    ) : media.link.asset._ref.includes("mp4") ? (
                                        <video controls className="w-auto max-h-[600px]">
                                            <source src={urlFor(media.link)} type="video/mp4" />
                                            Tu navegador no soporta la etiqueta de video.
                                        </video>
                                    ) : (
                                        <a
                                            href={urlFor(media.link)}
                                            download={media.title}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Descargar {media.title}
                                        </a>
                                    )
                                ) : null}

                                <p className="text-xs text-gray-500 mt-4">{formatDate(media.publishedAt)}</p>
                            </div>

                            <div className="w-1/2 max-lg:w-full max-lg:pt-2 max-lg:pl-0 flex flex-col pl-10">
                                <h1 className="text-4xl font-bold">{media.title}</h1>
                                <p className="text-base text-gray-600 mt-4">{media.description}</p>
                                <span className="text-xs text-gray-400 mt-2">{media.author}</span>
                                <button onClick={handleClick}>
                                    <a
                                        rel="noopener noreferrer"
                                        className="text-xs text-gray-400 mt-2 underline"
                                    >
                                        Ver más
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="h-[0.09rem] w-9/12 bg-black mt-8" />
                    <div className="h-[0.6rem] w-9/12 bg-black mt-16 mb-10" />
                </motion.div>
            </section>
        </>
    );
};

export default Blog;
