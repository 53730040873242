export const inicio = [
    {
        name: "Moisés Agudelo Ayala",
        role: "Líder y socio fundador",
        description: "Abogado de la Unidad Central del Valle Del Cauca. Adelantó postgrados en: Derecho Administrativo de la Universidad libre de Colombia; Derecho Notarial y Registral, Procesal Civil, laboral y relaciones industriales de la Universidad Externado de Colombia. Se ha desempeñado como Abogado Jefe del departamento jurídico del Municipio de Tuluá (V). Notario de la República durante 15 años, profesor universitario. Actualmente es director y comentarista Jurídico radial del programa Usted tiene derecho en la Emisora La Cariñosa de RCN, comentarista Jurídico de TV del Magazin Cada Mañana Canal PSC, abogado externo de varias empresas del estado, fundador y abogado jefe de la firma M&ABOGADOS S.A.",
        img: "assets/MoisesAgudelo1.jpeg"
    },
    {
        name: "Yamileth Ocampo Osorio",
        role: "Coordinadora y socia fundadora",
        description: "Contadora pública egresada de la Unidad Central del Valle Del Cauca, con alta experiencia en áreas de derecho notarial y registral, derecho privado, civil y de familia. Amplia experiencia directiva y gerencial de empresas, adelanta pregrado en derecho en la Unidad Central del Valle Del Cauca. Actualmente coordinadora de la firma M&ABOGADOS S.A.",
        img: "/assets/YamilethOcampo1.jpeg"
    },
];
export const aboutus = [
    {
        "text1": "Estamos centrados en ayudar personas a que puedan hacer válidos sus derechos y lo hacemos a través de nuestra profesión y experiencia. Somos un grupo de abogados liderados por Moises Agudelo Ayala... Que trabajamos con el propósito de brindar un apoyo jurídico eficaz. Concebido sobre los valores de la honestidad, el respeto, la honradez y la responsabilidad profesional, buscando por sobre todo la convivencia pacífica del conglomerado humano, brindándole herramientas jurídicas que satisfagan sus diferencias de una manera objetiva y ecuánime.",
    },
    {
        "text2": "Profesionales en derecho, con la misión de atender las necesidades jurídicas de nuestros clientes, dispuestos a brindar soluciones integrales que beneficien a todo el país. Asesoramos en la elaboración de acciones constitucionales, derechos de petición y otros trámites, ante entidades públicas y privadas... Representamos los intereses de personas afectadas en su actividad laboral, familiar y económica, lo hacemos a través de trámites legales que permitan determinar responsabilidades civiles, contractuales y extracontractuales. Ejecutamos procesos de división material, venta del bien común, prescripciones ordinarias y extraordinarias y todos los procesos que se realizan ante la jurisdicción civil."
    }
]
export const areas = [
    {
        id: "area-1",
        imgUrl: "/assets/area1.webp",
        title: "Derecho Administrativo",
        description: "En esta área representamos los intereses de los particulares cuando hayan sido lesionados por el estado, eventualmente por: falla en el servicio por responsabilidad médica, falla en el servicio por acción u omisión por parte de miembros de las fuerzas armadas; perjuicios ocasionados, por parte del INPEC, a la población privada de la libertad; accidentes de tránsito por mala señalización y mal estado de las vías y otros escenarios más.",
    },
    {
        id: "area-2",
        imgUrl: "/assets/area2.jpg",
        title: "Notarial y registral",
        description: "Garantizamos que todos los procesos legales, documentos públicos, contratos y otros se registren y estén al servicio de la comunidad, especialmente asesorando y acompañándolos, particularmente en: Divorcios; sucesiones; testamentos; unión marital y sociedad patrimonial entre compañeros permanentes; fideicomisos; divisorios; venta de la nuda propiedad; matrimonios; venta de bien de menor de edad; cancelación de usufructo; custodia y regulación de visitas; permiso para la salida del país de menor de edad, y todo lo concerniente con el ramo.",
    },
    {
        id: "area-3",
        imgUrl: "/assets/area3.png",
        title: "Laboral",
        description: "Asesoramos aspectos relacionados entre trabajadores y empleadores. Especialmente en: reclamación de todo tipo de pensiones; en instancias administrativas, como instancias jurisdiccionales; corrección de historias laborales; traslado de un fondo pensional a otro - nulidad de afiliación -; pensión de sobrevivientes; despido injusto; controversias de dictámenes de pérdida de capacidad laboral; y todo lo concerniente con la especialidad.",
    },
    {
        id: "area-4",
        imgUrl: "/assets/area4.jpg",
        title: "Civil",
        description: "Acompañamos, con una buena asesoría legal, en los siguientes aspectos: Reclamación de perjuicios originados en accidentes de tránsito y todo tipo de accidentes; responsabilidad por falla médica presunta; incumplimiento de contratos; procesos de prescripción; procesos divisorios; recuperación de bienes inmuebles; deslinde y amojonamiento.",
    },
    {
        id: "area-5",
        imgUrl: "/assets/area5.webp",
        title: "Familia",
        description: "Prestamos asesoría especializada, en el área de derecho de familia, especialmente en: Divorcios; unión marital de hecho y sociedad patrimonial entre compañeros permanentes (cuando hay conflicto); regulación de custodia, visitas y alimentos; procesos de apoyo judicial (antes denominados de interdicción); sucesiones; reclamación e impugnación de patria potestad; privación de la patria potestad; permiso de salidas del país; reclamación y regulación de cuota de alimentos.",
    },
    {
        id: "area-6",
        imgUrl: "/assets/bg5.jpg",
        title: "Ejecutivos",
        description: "Brindamos asesoramiento profesional para procesos ejecutivos, con fines de recuperación de cartera; ejecutivos por alimentos; ejecutivos por obligación de hacer (incumplimientos de contratos de obra); ejecutivos originados en sentencias, letras de cambio, pagarés, cheques, facturas; ejecutivos por incumplimientos contractuales (promesas de compraventa) y todo lo concerniente con esta área.",

    },
];

export const team = [
    {
        id: "person-1",
        name: "Brajhan Mendoza",
        caption: "Abogado de la Universidad San Buenaventura de Cali, experiencia como dependiente judicial, coordinador jurídico y en apoyo jurídico en la empresa Sentinel Procesal S.A.S.; abogado contratista en la Oficina de Atención de Víctimas de la Secretaría de Gobierno, Convivencia y Seguridad Ciudadana del municipio de Tuluá (V) en el año 2022. Desde el año 2021 hasta la actualidad me desempeño como abogado en la empresa M&ABOGADOS S.A., en las áreas de derecho administrativo; laboral y seguridad social, así como en otras áreas del derecho privado. Actualmente curso el posgrado en derecho administrativo en la Universidad Libre de Colombia, Seccional Cali. Poseo habilidades en redacción de demandas, recursos, y una excelente capacidad para trabajar bajo presión.",
        img: "/assets/abogados/abogadoBrajhan.jpeg"
    },
    {
        id: "person-2",
        name: "Vivian Marcela",
        caption: "Abogada de la Unidad Central del Valle, con seis años de experiencia en el área de Derecho civil, Familia y Notariado. Me caracterizo por ser una persona responsable, comprometida con la operación, las estrategias jurídicas y líder en el desarrollo de las actividades, con capacidad para trabajar en grupo y bajo presión, con dominio en el idioma inglés. Como abogada, mi objetivo profesional es dar un servicio de calidad a nuestros clientes, asumiendo cada caso con responsabilidad para defender con ética profesional a quienes confían en nuestra competencia.",
        img: "/assets/abogados/abogadaVivi.jpeg"
    },
    {
        id: "person-3",
        name: "Joan Argemiro",
        caption: "Abogado de la Unidad Central del Valle, con dominio del idioma inglés. Apoyo jurídico en procesos civiles, laborales, seguridad social y administrativos, con facilidad de socialización y atención al público. Con experiencia en dependencia judicial, proyección de escritos judiciales, acciones constitucionales y providencias judiciales. Participante en el semillero de Derecho Procesal (ICDP 2022), siendo coautor en el escrito académico 'El proceso y la garantía de los derechos humanos con enfoque de género en mujeres y comunidad LGBTIQ+'.",
        img: "/assets/abogados/abogadoJoan.jpeg"
    },
    {
        id: "person-4",
        name: "Uriel David",
        caption: "Abogado en formación de la Unidad Central del Valle. Con experiencia en revisión documental de trámites vehiculares de tránsito y como dependiente judicial encargado de realizar notificaciones, proyección de memoriales y atención al público. Caracterizado por el compromiso a nivel organizacional, responsabilidad, y capacidad para trabajar en grupo y en los diferentes escenarios que requiera la organización.",
        img: "/assets/abogados/abogadoUriel.JPG"
    },
]

export const team2 = [
    {
        id: "person-1",
        name: "Yamileth Ocampo Osorio",
        caption: "Contadora pública - Unidad Central del Valle Del Cauca\n- Adelanta pregrado en derecho.\n- Actualmente coordinadora de la firma M&ABOGADOS S.A.",
        img: "/assets/yamileth2.jpeg"
    },
    {
        id: "person-2",
        name: "Moises Agudelo Ayala",
        caption: "Abogado especialista en:\n- Derecho administrativo - Universidad Libre de Cali\n- Especialista en Derecho Laboral y relaciones industriales - Universidad Externado\n- Especialista en procesal civil - Universidad Externado\n- Postgrado especialización en derecho notarial y registral - Universidad Externado\n- Actualmente adelanto maestría en pedagogía\n- Docente universitario\n- Ex-notario de la república (15 años)\n- Fundador y director de la firma",
        img: "/assets/moises2.jpg"
    }
]
